export const sequenceMap = {
  "big schroeder": [
    1,
    2,
    6,
    22,
    90,
    394,
    1806,
    8558,
    41586,
    206098,
    1037718,
    5293446,
    27297738,
  ],
  catalan: [
    1,
    1,
    2,
    5,
    14,
    42,
    132,
    429,
    1430,
    4862,
    16796,
    58786,
    208012,
    742900,
    2674440,
    9694845,
    35357670,
    129644790,
    477638700,
  ],
  "catalan sq": [
    1,
    2,
    5,
    14,
    42,
    132,
    429,
    1430,
    4862,
    16796,
    58786,
    208012,
    742900,
    2674440,
    9694845,
  ],
  "catalan cubed": [
    1,
    3,
    9,
    28,
    90,
    297,
    1001,
    3432,
    11934,
    41990,
    149226,
    534888,
    1931540,
    7020405,
    25662825,
    94287120,
    347993910,
    1289624490,
    4796857230,
    17902146600,
    67016296620,
    251577050010,
    946844533674,
    3572042254128,
    13505406670700,
    51166197843852,
    194214400834356,
  ],
  "central binomial": [
    1,
    2,
    6,
    20,
    70,
    252,
    924,
    3432,
    12870,
    48620,
    184756,
    705432,
    2704156,
  ],
  "central trinomial": [
    1,
    1,
    3,
    7,
    19,
    51,
    141,
    393,
    1107,
    3139,
    8953,
    25653,
    73789,
    212941,
    616227,
    1787607,
    5196627,
  ],
  "delannoy numbers": [
    1,
    3,
    13,
    63,
    321,
    1683,
    8989,
    48639,
    265729,
    1462563,
    8097453,
    45046719,
    251595969,
    1409933619,
    7923848253,
    44642381823,
    252055236609,
    1425834724419,
    8079317057869,
    45849429914943,
    260543813797441,
    1482376214227923,
    8443414161166173,
  ],
  fibonacci: [
    1,
    1,
    2,
    3,
    5,
    8,
    13,
    21,
    34,
    55,
    89,
    144,
    233,
    377,
    610,
    987,
    1597,
    2584,
    4181,
    6765,
    10946,
    17711,
    28657,
    46368,
    75025,
    121393,
    196418,
    317811,
    514229,
  ],
  "fine numbers": [
    1,
    0,
    1,
    2,
    6,
    18,
    57,
    186,
    622,
    2120,
    7338,
    25724,
    91144,
    325878,
    1174281,
    4260282,
  ],
  "gamma riordan numbers": [
    1,
    0,
    1,
    1,
    3,
    6,
    15,
    36,
    91,
    232,
    603,
    1585,
    4213,
    11298,
    30537,
    83097,
    227475,
    625992,
    1730787,
    4805595,
  ],
  geometric: [
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
  ],
  "geometric sq": [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
  ],
  "hex numbers": [
    1,
    1,
    3,
    10,
    36,
    137,
    543,
    2219,
    9285,
    39587,
    171369,
    751236,
    3328218,
    14878455,
    67030785,
  ],
  "little schroeder": [
    1,
    1,
    3,
    11,
    45,
    197,
    903,
    4279,
    20793,
    103049,
    518859,
    2646723,
  ],
  "lucas numbers": [
    2,
    1,
    3,
    4,
    7,
    11,
    18,
    29,
    47,
    76,
    123,
    199,
    322,
    521,
    843,
    1364,
    2207,
    3571,
    5778,
    9349,
    15127,
    24476,
    39603,
    64079,
  ],
  motzkin: [
    1,
    1,
    2,
    4,
    9,
    21,
    51,
    127,
    323,
    835,
    2188,
    5798,
    15511,
    41835,
    113634,
    310572,
    853467,
    2356779,
    6536382,
  ],
  "motzkin-1": [
    1,
    1,
    1,
    2,
    4,
    9,
    21,
    51,
    127,
    323,
    835,
    2188,
    5798,
    15511,
    41835,
    113634,
    310572,
    853467,
    2356779,
    6536382,
  ],
  "m tilde": [
    1,
    1,
    1,
    2,
    4,
    9,
    21,
    51,
    127,
    323,
    835,
    2188,
    5798,
    15511,
    41835,
    113634,
    310572,
    853467,
    2356779,
    6536382,
    18199284,
    50852019,
    142547559,
    400763223,
    1129760415,
    3192727797,
    9043402501,
    25669818476,
    73007772802,
    208023278209,
    593742784829,
    1697385471211,
    4859761676391,
    13933569346707,
  ],
  ones: [
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
  ],
  "pell p": [
    0,
    1,
    2,
    5,
    12,
    29,
    70,
    169,
    408,
    985,
    2378,
    5741,
    13860,
    33461,
    80782,
    195025,
    470832,
    1136689,
    2744210,
    6625109,
    15994428,
    38613965,
    93222358,
    225058681,
    543339720,
    1311738121,
    3166815962,
    7645370045,
    18457556052,
    44560482149,
    107578520350,
    259717522849,
  ],
  "pell q": [
    1,
    1,
    3,
    7,
    17,
    41,
    99,
    239,
    577,
    1393,
    3363,
    8119,
    19601,
    47321,
    114243,
    275807,
    665857,
    1607521,
    3880899,
    9369319,
    22619537,
    54608393,
    131836323,
    318281039,
    768398401,
    1855077841,
    4478554083,
    10812186007,
    26102926097,
    63018038201,
    152139002499,
    367296043199,
  ],
  "powers of two": [
    1,
    2,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512,
    1024,
    2048,
    4096,
    8192,
    16384,
    32768,
    65536,
    131072,
    262144,
    524288,
    1048576,
    2097152,
    4194304,
  ],
  "r numbers": [
    1,
    0,
    1,
    1,
    3,
    6,
    15,
    36,
    91,
    232,
    603,
    1585,
    4213,
    11298,
    30537,
    83097,
    227475,
    625992,
    1730787,
    4805595,
  ],
  rna: [
    1,
    1,
    1,
    2,
    4,
    8,
    17,
    37,
    82,
    185,
    423,
    978,
    2283,
    5373,
    12735,
    30372,
    72832,
    175502,
    424748,
    1032004,
    2516347,
    6155441,
    15101701,
    37150472,
    91618049,
    226460893,
    560954047,
    1392251012,
    3461824644,
    8622571758,
    21511212261,
    53745962199,
    134474581374,
  ],
  "spoiled child": [
    1,
    2,
    5,
    15,
    51,
    188,
    731,
    2950,
    12235,
    51822,
    223191,
    974427,
    4302645,
    19181100,
    86211885,
  ],
  "ternary numbers": [
    1,
    1,
    3,
    12,
    55,
    273,
    1428,
    7752,
    43263,
    246675,
    1430715,
    8414640,
    50067108,
    300830572,
    1822766520,
    11124755664,
    68328754959,
    422030545335,
    2619631042665,
    16332922290300,
    102240109897695,
    642312451217745,
    4048514844039120,
  ],
  "ternary squared": [
    1,
    2,
    7,
    30,
    143,
    728,
    3876,
    21318,
    120175,
    690690,
    4032015,
    23841480,
    142498692,
    859515920,
    5225264024,
    31983672534,
    196947587823,
    1219199353190,
    7583142491925,
    47365474641870,
    296983176369495,
    1868545312633440,
  ],
  zeros: [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ],
};

export const sequenceNames = Object.keys(sequenceMap);

export const options = sequenceNames.map((sequenceName, index) => {
  return {
    id: index,
    name: sequenceName,
    sequence: sequenceMap[sequenceName],
  };
});
