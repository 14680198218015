export const SELECT_SEQUENCE = "SELECT_SEQUENCE";
export const SET_CUSTOM_SEQUENCE = "SET_CUSTOM_SEQUENCE";
export const ADD_ZERO = "ADD_ZERO";
export const DISPLAY_FEWER_TERMS = "DISPLAY_FEWER_TERMS";
export const DISPLAY_MORE_TERMS = "DISPLAY_MORE_TERMS";
export const FETCH_MATRIX_START = "FETCH_MATRIX_START";
export const FETCH_MATRIX_SUCCESS = "FETCH_MATRIX_SUCCESS";
export const FETCH_MATRIX_FAIL = "FETCH_MATRIX_FAIL";
export const FETCH_OEIS_SEQUENCE_START = "FETCH_OEIS_SEQUENCE_START";
export const FETCH_OEIS_SEQUENCE_SUCCESS = "FETCH_OEIS_SEQUENCE_SUCCESS";
export const FETCH_OEIS_SEQUENCE_FAIL = "FETCH_OEIS_SEQUENCE_FAIL";
